import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/monitory-point-type",
    method: "Get",
    params: params,
  });
}

// 增加
export function addMonitoryPointType(requestBody) {
  return request({
    url: "/monitory-point-type",
    method: "Post",
    data: requestBody,
  });
}

// 编辑
export function editMonitoryPointType(requestBody) {
  return request({
    url: "/monitory-point-type",
    method: "Patch",
    data: requestBody,
  });
}

// 删除
export function deleteMonitoryPointType(params) {
  return request({
    url: "/monitory-point-type",
    method: "Delete",
    params: params,
  });
}

// 获取MonitoryPointType option
export function getMonitoryPointTypeOptions() {
  return request({
    url: "/monitory-point-type/option",
    method: "Get",
  });
}
